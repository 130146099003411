import {
  GET_ARAS_CARDS,
  ADD_ARAS_CARDS,
  UPDATE_ARAS_CARDS,
  AUTO_ARAS_PIN_DOWNLOAD,
  ARAS_NOTIFICATION,
  ARAS_REPORT,
} from 'redux/admin-constants';

const initialState = {
  aras_cards: [],
  autoPindownload: [],
  autoPinReport: [],
  arascom_notification: {
    message: '',
    isSuccess: 0,
  },
  updatearas_cards: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ARAS_CARDS: {
      return {
        ...state,
        aras_cards: payload,
      };
    }
    case ADD_ARAS_CARDS: {
      return {
        ...state,
        aras_cards: [payload, ...state.aras_cards],
      };
    }
    case UPDATE_ARAS_CARDS: {
      return {
        ...state,
        updatearas_cards: state.aras_cards.map((item) =>
          item.card_id === payload.card_id ? { ...item, ...payload } : item
        ),
      };
    }
    case AUTO_ARAS_PIN_DOWNLOAD: {
      return {
        ...state,
        aras_cards: state.aras_cards.map((item) => {
          if (payload.find((data) => data.cardId === item?.card_id)) {
            return {
              ...item,
              ...{
                auto_download: payload.find(
                  (data) => data.cardId === item?.card_id
                )?.download,
              },
            };
          } else {
            return item;
          }
        }),
      };
    }

    case ARAS_NOTIFICATION:
      return {
        ...state,
        arascom_notification: payload.message
          ? payload
          : initialState.arascom_notification,
      };

    case ARAS_REPORT: {
      return {
        ...state,
        autoPinReport: payload,
      };
    }

    default:
      return state;
  }
};
export default reducer;
