import { GET_CARD_SEARCH } from 'redux/admin-constants';

const initialState = {
  cardsearch: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CARD_SEARCH: {
      return {
        ...state,
        cardsearch: payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;
