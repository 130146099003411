const { GET_RECHARGE_SUMMARY, GET_RECHARGE_SUMMARY_PRINT } = require("redux/admin-constants");

const initialState = {
  rechargesummary: [],
  print_report: [],
}

const reducer = (state = initialState, action) => {
  const {
    type,
    payload
  } = action;

  switch (type) {
    case GET_RECHARGE_SUMMARY: {

      return {
        ...state,
        rechargesummary: payload,
      }
    }

    case GET_RECHARGE_SUMMARY_PRINT:
      return {
        ...state,
        print_report: payload
      }

    default:
      return state;
  }
}
export default reducer;