const {
  GET_CUSTOMER_USER,
  USER_DETAILS_NOTIFICATION,
  UPDATE_USER_DETAILS,
  INSERT_USER_DETAILS,
  DELETE_USER_DETAILS,
  DELETE_USER_DETAILS_CUSTOMER,
  GET_LOGIN_USER,
  GET_CUSTOMER_USER_SALES,
  REPRINT_DATA,
  GET_CUSTOMER_INVOICE_REPORT,
  DELETE_CUSTOMER_INVOICE_REPORT,
} = require('redux/constants');

const initialState = {
  users: [],
  user_dettails_notification: {
    message: '',
    isSuccess: 1,
  },
  user_report: [],
  reprint_data: {},
  invoiceReport: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CUSTOMER_USER: {
      return {
        ...state,
        users: payload,
      };
    }
    case INSERT_USER_DETAILS: {
      return {
        ...state,
        users: payload,
      };
    }

    case UPDATE_USER_DETAILS: {
      return {
        ...state,
        users: state.users?.data?.map((item) =>
          item.username === payload.username ? { ...item, ...payload } : item
        ),
      };
    }
    case USER_DETAILS_NOTIFICATION:
      return {
        ...state,
        user_dettails_notification: payload.message
          ? payload
          : initialState.user_dettails_notification,
      };
    case DELETE_USER_DETAILS:
      return {
        ...state,
        users: {
          ...state.users,
          ...{
            data: state.users.data?.filter(
              (item) => item.user_id !== payload.user_id
            ),
          },
        },
      };
    case GET_CUSTOMER_USER_SALES:
      return {
        ...state,
        user_report: payload,
      };

    case GET_LOGIN_USER: {
      return {
        ...state,
        loginuser: payload,
      };
    }
    case GET_CUSTOMER_INVOICE_REPORT: {
      return {
        ...state,
        invoiceReport: payload,
      };
    }
    case DELETE_CUSTOMER_INVOICE_REPORT: {
      return {
        ...state,
        deleteInvoiceReport: payload,
      };
    }
    case REPRINT_DATA: {
      return {
        ...state,
        reprint_data: payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;
