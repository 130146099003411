const { GET_HOME_CONTENT } = require("redux/admin-constants");

const initialState = { 
    adminHomeData:[],
}

const reducer = (state = initialState,  action) => {
const {
  type,
  payload
} = action;

switch (type) {
  case GET_HOME_CONTENT: {
    
    return {
      ...state,
      adminHomeData: payload,
    }
  }

  default:
    return state;
  }
}

export default reducer;