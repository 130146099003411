import { GET_CARD_DENOMINATION_LIST, CARD_DENOMINATION_NOTIFICATION, UPDATE_CARD_DENOMINATION_LIST, DELETE_CARD_DENOMINATION_LIST} from 'redux/admin-constants';

const initialState = { 
  card_denomination:[],
  card_denomination_notification:{
      message: '',
      isSuccess: 1
  }
}

const reducer = (state = initialState,  action) => {
const {
  type,
  payload
} = action;

switch (type) {
  case GET_CARD_DENOMINATION_LIST: {
    return {
      ...state,
      card_denomination: payload,
    }
  }

  case CARD_DENOMINATION_NOTIFICATION:
    return { ...state, 
      card_denomination_notification: (payload.message) ? payload : initialState.card_denomination_notification
  }

  case UPDATE_CARD_DENOMINATION_LIST:
    return {
      ...state, 
      card_denomination:
       state.card_denomination.map(item => item.denomination_id === payload.denomination_id ? 
        { ...item, ...payload} : item 
      )
  }

  case DELETE_CARD_DENOMINATION_LIST:
    return { ...state, 
      card_denomination: state.card_denomination.filter(item => item.denomination_id !== payload.denoid)
  }

  default:
    return state;
  }
}

export default reducer;