import {
  GET_BUS_STATION,
  POST_FLIX_BUS_SEARCH,
  SET_DEPARTURE_CART,
  SET_RETURN_CART,
  GET_CART,
  GET_PASSENGER,
  SAVE_PASSENGER,
  GET_LUGGAGE,
  MANAGE_LUGGAGE,
  MANAGE_VOUCHER,
  FLIXBUS_PAYMENT_DETAILS
  } from "../constants";
    
  const reducer = (state = {}, action) => {
    const {
      type,
      payload
    } = action;
  
    switch (type) {
      case GET_BUS_STATION: {
        return {
          ...state,
          busstation: payload,
        }
      }
      case POST_FLIX_BUS_SEARCH:{
        return {
          ...state,
          postflixbusstation: payload,
        }
      }
      case SET_DEPARTURE_CART:{
        return {
          ...state,
          setdeparturecart: payload,
        }
      }
      case SET_RETURN_CART:{
        return {
          ...state,
          setreturncart: payload,
        }
      }
      case GET_CART:{
       
        return {
          ...state,
          getcarddetails: payload,
        }
      }
      case GET_PASSENGER:{
        return {
          ...state,
          getpassengerdetails: payload,
        }
      }
      case SAVE_PASSENGER:{
        return {
          ...state,
          savepassenger: payload,
        }
      }
      case GET_LUGGAGE:{
        return {
          ...state,
          getluggage: payload,
        }
      }

      case MANAGE_LUGGAGE:{
        
        return {
          ...state,
          getluggage: state.getluggage.map(item => item.ancillary_offer_reference_id === payload?.offers[0]?.ancillary_offer_reference_id ? 
            { ...item, ...payload?.offers[0]} : item 
          ),
        }
      }
      case MANAGE_VOUCHER:{
        return {
          ...state,
          voucherdetails: payload,
        }
      }
      case FLIXBUS_PAYMENT_DETAILS:{
        return {
          ...state,
          flixbuspayment: payload,
        }
      }

      default:
          return state;
      }
  }
  
  export default reducer;