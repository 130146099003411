import {
  GET_CARDS,
  GET_FAV_CARDS,
  GET_NEW_CARDS,
  GET_FILTERED_CARDS,
  SET_PURCHASE,
  GET_PARTICULAR_CARD,
  FAV_CARDS_STATUS,
  ONLINECARD_NOTIFICATION,
} from '../constants';

const reducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CARDS: {
      return {
        ...state,
        cards: payload.cards,
      };
    }

    case GET_FAV_CARDS: {
      return {
        ...state,
        favCards: payload.favCards,
      };
    }

    case GET_NEW_CARDS: {
      return {
        ...state,
        newCards: payload.newCards,
      };
    }

    case GET_FILTERED_CARDS: {
      return {
        ...state,
        filteredCards: payload,
      };
    }

    case SET_PURCHASE: {
      return {
        ...state,
        purchasedcards: payload,
      };
    }

    case GET_PARTICULAR_CARD: {
      return {
        ...state,
        getparticularcard: payload,
      };
    }

    case FAV_CARDS_STATUS: {
      return {
        ...state,
        favCards: payload.isDel
          ? state.favCards.filter(
              (item) => item.denomination_id !== payload.deno_id
            )
          : [
              state.cards.filter(
                (item) => item.denomination_id === payload.deno_id
              )?.[0],
              ...state.favCards,
            ],
      };
    }
    case ONLINECARD_NOTIFICATION: {
      return {
        ...state,
        onlineCardNotification: payload.message ? payload : '',
      };
    }

    default:
      return state;
  }
};

export default reducer;
