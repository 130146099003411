import { batch } from 'react-redux';
import {
  BATCH_FORMAT_DATA,
  BATCH_FORMAT_NOTIFICATION,
  BATCH_CARD_NOTIFICATION,
  BATCH_SUMMARY_DATA,
  PIN_BATCH_STATUS,
  MANAGE_PIN_REMOVE,
  BATCH_PIN_NOTIFICATION,
  BATCH_DENO_INFO,
  MANAGE_REMOVE,
} from 'redux/admin-constants';

const initialState = {
  batch_format: [],
  batch_notification: {
    message: '',
    isSuccess: 1,
    duplicateValues: null,
  },
  batch_card_notification: {
    message: '',
    isSuccess: 1,
    duplicateValues: null,
  },
  batch_pin_notification: {
    message: '',
    isSuccess: 1,
    duplicateValues: null,
  },
  batch_summary: [],
  batch_status: [],
  batch_deno_info: {},
  batchdelete: {},
};
const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case BATCH_FORMAT_DATA: {
      return {
        ...state,
        batch_format: payload,
      };
    }
    case BATCH_FORMAT_NOTIFICATION: {
      return {
        ...state,
        batch_notification: payload?.message ? payload : initialState.payload,
      };
    }
    case BATCH_CARD_NOTIFICATION: {
      return {
        ...state,
        batch_card_notification: payload?.message
          ? payload
          : initialState.payload,
      };
    }
    case BATCH_SUMMARY_DATA: {
      return {
        ...state,
        batch_summary: payload,
      };
    }
    case PIN_BATCH_STATUS: {
      return {
        ...state,
        batch_status: payload,
      };
    }
    case BATCH_PIN_NOTIFICATION:
      return {
        ...state,
        batch_pin_notification: payload.message
          ? payload
          : initialState.batch_pin_notification,
      };
    case MANAGE_PIN_REMOVE:
      return {
        ...state,
        batch_status: state.batch_status.filter(
          (item) => !payload.batchids.includes(item.batch_id)
        ),
        batch_summary: state.batch_summary.map((item) => {
          if (item.batch_details_id === payload.batchdetailsid) {
            item.totalpins = item.totalpins - payload.batchids.length;
          }
          return item;
        }),
      };
    case MANAGE_REMOVE:
      return {
        ...state,
        batchdelete: payload,
      };
    case BATCH_DENO_INFO: {
      return {
        ...state,
        batch_deno_info: payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
