const { GET_LANDING_POSTER, INSERT_POSTER_DETAILS, POSTER_DETAILS_NOTIFICATION, DELETE_POSTER_DETAILS } = require("redux/admin-constants");

const initialState = { 
  posters:[],
  poster_dettails_notification:{
      message: '',
      isSuccess: 1
  }
}

const reducer = (state = initialState,  action) => {
const {
  type,
  payload
} = action;

switch (type) {
  case GET_LANDING_POSTER: {
    
    return {
      ...state,
      posters: payload,
    }
  }

  case INSERT_POSTER_DETAILS: {
    return { ...state, posters:[...state.posters, payload] }
  }

  case POSTER_DETAILS_NOTIFICATION:
    return { ...state, 
        poster_dettails_notification: (payload.message) ? payload : initialState.poster_dettails_notification
  }

  case DELETE_POSTER_DETAILS:
      return {
        ...state,
        posters: state.posters.filter(item => item.mgm_poster_id !== payload.posterID)
      }

  default:
    return state;
  }
}

export default reducer;