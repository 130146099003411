import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import auth from './auth/reducer';
import marquee from './marquee/reducer';
import account from './account/reducer';
import directrecharge from './direct-recharge/reducer';
import saleshistory from './saleshistory/reducer';
import cards from './cards/reducer';
import flixbus from './flix-bus/reducer';
import callthrough from './call-through/reducer';
import internationalrecharge from './internationalrecharge/reducer';
import adminCardDetails from './admin/cards/reducer';
import adminCardDenomination from './admin/carddenomination/reducer';
import batchDetails from './admin/batch/reducer';
import userDetails from './user/reducer';
import userProfiles from './profile/reducer';
import configuration from './admin/configuration/reducer';
import guestAdminDetails from './admin/guest-admin/reducer';
import myAccount from './CustomerAdmin/myaccount/reducer';
import customerUser from './CustomerAdmin/customeruser/reducer';
import inchargelist from './admin/inchargelist/reducer';
import ticketPriceCards from './CustomerAdmin/pricemanagement/reducer';
import ticketprice from './admin/ticketprice/reducer';
import salesreport from './admin/salesreport/reducer';
import stockreport from './admin/stockreport/reducer';
import customerBalancedetails from './admin/customerbalance/reducer';
import rechargeSummary from './admin/rechargesummary/reducer';
import profitreport from './admin/profitreport/reducer';
import myAccountInfo from './admin/myaccount/reducer';
import arascocomDetails from './admin/arascom/reducer';
import salesreportcardwise from './admin/cardwisesalesreport/reducer';
import openbalancereport from './admin/openbalancereport/reducer';
import cashflowreport from './admin/cashflowreport/reducer';
import invoicereport from './admin/invoicereport/reducer';
import invoicelist from './admin/invoicegenerate/reducer';
import adminUser from './admin/user-admin/reducer';
import salesComparison from './admin/salescomparison/reducer';
import marqueeOption from './admin/marqueeoption/reducer';
import mfBusSalesReport from './admin/mfbus-salesreport/reducer';
import resellerCardsDetails from './reseller/cardprice/reducer';
import profileReducer from './reseller/profile/reducer';
import pinsdelete from './admin/pinsdelete/reducer';
import managepricereport from './admin/manageprice/reducer';
import callthroughlist from './admin/callthrough/reducer';
import webUser from './admin/webUserService/reducer';
import internationalPriceupload from './admin/international-priceupload/reducer';
import mailOption from './admin/mailoption/reducer';
import priceReducer from './reseller/manageprice/reducer';
import creditbalance from './reseller/credit-balance/reducer';
import rechargesummary1 from './reseller/rechargesummary/reducer';
import openbalance from './reseller/openBalance/reducer';
import invoicelistreseller from './reseller/invoiceList/reducer';
import resellerSalesreport from './reseller/salesreport/reducer';
import resellerSalesComparison from './reseller/salescomparison/reducer';
import resellerCashFlowReport from './reseller/cashflowreport/reducer';
import userReducer from './reseller/user/reducer';
import resellerProfitreport from './reseller/profitreport/reducer';
import resellerInvoiceGenerate from './reseller/invoicegenerate/reducer';
import ResellerMarqueeOption from './reseller/marqueeoption/reducer';
import userAlert from './admin/usernotification/reducer';
import startPage from './admin/startpage/reducer';
import bulkPurchase from './bulk-purchase/reducer';
import cardsearch from './admin/cardsearch/reducer';
import adminHome from './admin/adminhome/reducer';
import resellerHome from './reseller/resellerhome/reducer';
import resellerMarquee from './reseller/marqueeoption/reducer';
import resellerMyAccount from './reseller/myaccount/reducer';
import adminUserRegister from './admin/userregistration/reducer';
import konfiguration from './reseller/konfiguration/reducer';
import userActivation from './admin/useractivation/reducer';
import saleslimit from './admin/saleslimit/reducer';
import resellerSalesLimit from './reseller/saleslimit/reducer';
import resellerTicketPrice from './reseller/ticketprice/reducer';
import subDomain from './admin/subdomain/reducer';

import common from './common/reducer';
import userMenus from './UserMenu/reducer';
import WorkGropuMapping from './WorkGroupMapping/reducer';
import userWorkGroup from './UserWorkGroup/reducer';
import workGroupMenu from './WorkGroupMenu/reducer';
const reducers = combineReducers({
  common,
  userMenus,
  userWorkGroup,
  workGroupMenu,
  WorkGropuMapping,
  menu,
  settings,
  auth,
  marquee,
  account,
  directrecharge,
  saleshistory,
  cards,
  flixbus,
  internationalrecharge,
  adminCardDetails,
  adminCardDenomination,
  batchDetails,
  userDetails,
  callthrough,
  userProfiles,
  configuration,
  guestAdminDetails,
  customerBalancedetails,
  myAccount,
  customerUser,
  inchargelist,
  ticketPriceCards,
  ticketprice,
  stockreport,
  salesreport,
  rechargeSummary,
  profitreport,
  myAccountInfo,
  salesreportcardwise,
  arascocomDetails,
  openbalancereport,
  cashflowreport,
  invoicereport,
  invoicelist,
  adminUser,
  salesComparison,
  marqueeOption,
  mfBusSalesReport,
  resellerCardsDetails,
  profileReducer,
  pinsdelete,
  managepricereport,
  callthroughlist,
  adminUser,
  webUser,
  internationalPriceupload,
  mailOption,
  priceReducer,
  creditbalance,
  rechargesummary1,
  profileReducer,
  openbalance,
  invoicelistreseller,
  resellerSalesreport,
  resellerSalesComparison,
  resellerCashFlowReport,
  userReducer,
  resellerProfitreport,
  resellerInvoiceGenerate,
  ResellerMarqueeOption,
  userAlert,
  startPage,
  bulkPurchase,
  cardsearch,
  adminHome,
  resellerHome,
  resellerMarquee,
  resellerMyAccount,
  adminUserRegister,
  konfiguration,
  userActivation,
  saleslimit,
  resellerSalesLimit,
  resellerTicketPrice,
  subDomain,
});

export default reducers;
