import {
  GET_SUBDOMAIN_LIST,
  MANAGE_SUBDOMAIN,
  SUBDOMAIN_NOTIFICATION,
  DELETE_SUBDOMAIN_DETAILS,
  GET_RETAILER_LIST,
  ASSIGN_RETAILER_DATA,
} from '../../admin-constants';

const initialState = {
  subDomain_data: [],
  subDomain_manage: [],
  retailer_data: [],
  retailer_assign_data: [],
  subDomain_notification: {
    message: '',
    isSuccess: 1,
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_SUBDOMAIN_LIST: {
      return {
        ...state,
        subDomain_data: payload,
      };
    }
    case MANAGE_SUBDOMAIN: {
      return {
        ...state,
        subDomain_manage: payload,
      };
    }

    case GET_RETAILER_LIST: {
      return {
        ...state,
        retailer_data: payload,
      };
    }
    case SUBDOMAIN_NOTIFICATION: {
      return {
        ...state,
        subDomain_notification: payload.message
          ? payload
          : initialState.subDomain_notification,
      };
    }

    case ASSIGN_RETAILER_DATA:
      const updatedSubDomainData = state.subDomain_data.map((item) => {
        if (item.id === payload.subdomain_id) {
          return {
            ...item,
            status: 2,
            username: payload.username,
            user_id: payload.retailer_id,
          };
        }
        return item;
      });

      return {
        ...state,
        subDomain_data: updatedSubDomainData,
      };

    case DELETE_SUBDOMAIN_DETAILS:
      return {
        ...state,
        subDomain_data: state.subDomain_data.filter(
          (item) => item.id !== payload.subdomain_id
        ),
      };

    default:
      return state;
  }
};

export default reducer;
