import {
  GET_CALL_THROUGH_LIST,
  DELETE_CALL_THROUGH,
  UPDATE_CALL_THROUGH_DETAILS,
  INSERT_CALL_THROUGH_DETAILS,
  CALL_THROUGH_LIST_NOTIFICATION,
} from 'redux/admin-constants';

const initialState = {
  callthroughlist: [],
  callthroughlist_notification: {
    message: '',
    isSuccess: 1,
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CALL_THROUGH_LIST: {
      return {
        ...state,
        callthroughlist: payload,
      };
    }

    case INSERT_CALL_THROUGH_DETAILS: {
      return {
        ...state,
        callthroughlist: [payload, ...state.callthroughlist],
      };
    }

    case UPDATE_CALL_THROUGH_DETAILS: {
      return {
        ...state,
        callthroughlist: state.callthroughlist.map((item) =>
          item.callthrough_price_id === payload.id
            ? { ...item, ...payload }
            : item
        ),
      };
    }

    case DELETE_CALL_THROUGH: {
      return {
        ...state,
        callthroughlist: state.callthroughlist.filter(
          (item) => item.callthrough_price_id !== payload.callthroughId
        ),
      };
    }

    case CALL_THROUGH_LIST_NOTIFICATION: {
      return {
        ...state,
        callthroughlist_notification: payload.message
          ? payload
          : initialState.callthroughlist_notification,
      };
    }

    default:
      return state;
  }
};
export default reducer;
