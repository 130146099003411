const {
  GET_PIN_DELETE,
} = require('redux/admin-constants');

const initialState = {
  pindelete: [],
}; 

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PIN_DELETE: {
      return {
        ...state,
        pindelete: payload,
      };
    }
   
    default:
      return state;
  }
};

export default reducer;