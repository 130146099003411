const {
  GET_CARD_LIST,
  GET_CARD_TYPE,
  CARD_DETAILS_NOTIFICATION,
  UPDATE_CARD_DETAILS,
  INSERT_CARD_DETAILS,
  DELETE_CARD_DETAILS,
  GET_PRODUCT_GROUP,
  GET_PRICE_LIST,
} = require('redux/admin-constants');

const initialState = {
  cards: [],
  pricelist: [],
  card_type: [],
  product_group: [],
  card_dettails_notification: {
    message: '',
    isSuccess: 1,
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CARD_LIST: {
      return {
        ...state,
        cards: payload,
      };
    }
    case GET_PRICE_LIST: {
      return {
        ...state,
        pricelist: payload,
      };
    }
    case GET_CARD_TYPE: {
      return {
        ...state,
        card_type: payload,
      };
    }
    case GET_PRODUCT_GROUP: {
      return {
        ...state,
        product_group: payload,
      };
    }

    case INSERT_CARD_DETAILS: {
      return { ...state, cards: [payload, ...state.cards] };
    }

    case UPDATE_CARD_DETAILS: {
      return {
        ...state,
        cards: state.cards.map((item) =>
          item.card_id === payload.card_id ? { ...item, ...payload } : item
        ),
      };
    }
    case CARD_DETAILS_NOTIFICATION:
      return {
        ...state,
        card_dettails_notification: payload.message
          ? payload
          : initialState.card_dettails_notification,
      };
    case DELETE_CARD_DETAILS:
      return {
        ...state,
        cards: state.cards.filter((item) => item.card_id !== payload.cardid),
      };

    default:
      return state;
  }
};

export default reducer;
