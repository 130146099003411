import { GET_CASH_FLOW_REPORT, GET_CASH_FLOW_PDF } from 'redux/reseller-constants';
    
  const reducer = (state = {}, action) => {
    const {
      type,
      payload
    } = action;
  
    switch (type) {
      case GET_CASH_FLOW_REPORT: {
        return {
          ...state,
          cashflowreport: payload,
        }
      }
      case GET_CASH_FLOW_PDF: {
        return {
          ...state,
          cashflowpdf: payload,
        }
      }
      default:
          return state;
      }
  }
  
  export default reducer;