import {
  GET_RESELLER_CARDS,
  DELETE_CARD_NOTIFICATION,
  DELETE_CARD,
  UPDATE_RESELLER_CARD,
  GET_RESELLERPRICE_LIST,
} from '../../reseller-constants';

const initialState = {
  pricelist: [],
  resellerCards: [],
  updateCards: [],
  deleteCardNotification: {
    message: '',
    isSuccess: 1,
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_RESELLER_CARDS: {
      return {
        ...state,
        resellerCards: payload,
      };
    }
    case GET_RESELLERPRICE_LIST: {
      return {
        ...state,
        pricelist: payload,
      };
    }
    case DELETE_CARD: {
      return {
        ...state,
        resellerCards: state.resellerCards.map((item) =>
          item.prc_reseller_default_price_id ===
          payload.prc_reseller_default_price_id
            ? { ...item, ...payload }
            : item
        ),
      };
    }
    case DELETE_CARD_NOTIFICATION:
      return {
        ...state,
        deleteCardNotification: payload.message
          ? payload
          : initialState.deleteCardNotification,
      };
    case UPDATE_RESELLER_CARD:
      return {
        ...state,
        updateCards: state.resellerCards.map((item) =>
          item.denomination_id === payload.denomination_id
            ? { ...item, ...payload }
            : item
        ),
      };
    default:
      return state;
  }
};

export default reducer;
