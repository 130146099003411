import {
  GET_MARQUEE
} from "../constants";
  
const reducer = (state = {}, action) => {
  const {
    type,
    payload
  } = action;

  switch (type) {
    case GET_MARQUEE: {
      return {
        ...state,
        marquee: payload,
      }
    }
    default:
        return state;
    }
}

export default reducer;