import {
    GET_SALES_HISTORY_TODAY
  } from "../constants";
  
const reducer = (state = {}, action) => {
  const {
    type,
    payload
  } = action;

  switch (type) {
    case GET_SALES_HISTORY_TODAY: {
      return {
        ...state,
        saleshistorytoday: payload,
      }
    }
    default:
        return state;
    }
}

export default reducer;