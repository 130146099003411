import { GET_TICKET_PRICE } from "redux/constants";

const initialState = { 
  priceCards:[]
}

const reducer = (state = initialState,  action) => {
const {
  type,
  payload
} = action;

switch (type) {
  case GET_TICKET_PRICE: {
    
    return {
      ...state,
      priceCards: payload,
    }
  }
  default:
    return state;
  }
}

export default reducer;