const { GET_WEB_USER, 
    USER_DETAILS_NOTIFICATION, 
    UPDATE_USER_DETAILS, 
    INSERT_USER_DETAILS, 
    DELETE_WEBUSER_LIST,
    INSERT_IP_ADDRESS,
    GET_LOGIN_USER,
    GET_IP_ADDRESS,
    DELETE_IP_ADDRESS
   } = require("redux/admin-constants");
  
  const initialState = { 
    users:[],
    ipAddress:[],
    user_dettails_notification:{
        message: '',
        isSuccess: 1
    }
  }
  
  const reducer = (state = initialState,  action) => {
  const {
    type,
    payload
  } = action;
  
  switch (type) {
    case GET_WEB_USER: {
      
      return {
        ...state,
        users: payload,
      }
    }

    case INSERT_USER_DETAILS: {
      return { ...state, users: [payload, ...state.users] }
    }
    
    case GET_IP_ADDRESS: {
      
      return {
        ...state,
        ipAddress: payload,
      }
    }

    case INSERT_IP_ADDRESS: {
      return { ...state, ipAddress: [payload, ...state.ipAddress] }
    }

    case DELETE_IP_ADDRESS:
      return { ...state, 
        ipAddress: state.ipAddress.filter(item => item.usr_user_ip_id !== payload.ip_id)
    }
    
    case UPDATE_USER_DETAILS: {
      return {
        ...state, 
        users:
         state.users.map(item => item.username === payload.username ? 
          { ...item, ...payload} : item 
        )
      }
    }
  
    case USER_DETAILS_NOTIFICATION:
      return { ...state, 
        user_dettails_notification: (payload.message) ? payload : initialState.user_dettails_notification
    }
  
    case DELETE_WEBUSER_LIST:
    return { ...state, 
      users: state.users.filter(item => item.user_id !== payload.user_id)
  }
  
    case GET_LOGIN_USER:{
      return {
      ...state,
      loginuser: payload,
     }
    }
  
    default:
      return state;
    }
  }
  
  export default reducer;