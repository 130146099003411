import {
    GET_DIRECT_RECHARGE_CARDS, GET_DIRECT_RECHARGE_CARDS_BY_PROVIDER_ID, SET_DIRECT_RECHARGE_CARDS, DIRECT_RECHARGE_NOTIFICATION
  } from "../constants";

  const initialState = { 
    direct_recharge_notification:{
        message: '',
        isSuccess: 1
    }
  }
  
const reducer = (state = initialState, action) => {
  const {
    type,
    payload
  } = action;

  switch (type) {
    case GET_DIRECT_RECHARGE_CARDS: {
      return {
        ...state,
        rechargeCards: payload,
      }
    }
    case GET_DIRECT_RECHARGE_CARDS_BY_PROVIDER_ID:{
      return {
        ...state,
        getdirectrechargebyproviderid: payload,
      }
    }
    case SET_DIRECT_RECHARGE_CARDS:{
      return {
        ...state,
        setdirectrecharge: payload,
      }
    }
    case DIRECT_RECHARGE_NOTIFICATION:
      return { ...state, 
        direct_recharge_notification: (payload.message) ? payload : initialState.direct_recharge_notification
    }
    default:
        return state;
    }
}

export default reducer;