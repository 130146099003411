import { GET_CUSTOMERS,  GET_SALES_REPORT, GET_CARD_DENOMINATION_LIST } from 'redux/reseller-constants';

const initialState = {
  getCustomers: [],
  cardDenomination:[],
  salesReport: [],

}; 

const reducer = (state = initialState, action) => {

  const { type, payload } = action;

  switch (type) {

    case GET_CUSTOMERS: {
      return {
        ...state,
        getCustomers: payload,
      }
    }

    case GET_SALES_REPORT: {
      return {
        ...state,
        salesReport: payload,
      };
    }

    case GET_CARD_DENOMINATION_LIST: {
      return {
        ...state,
        cardDenomination: payload,
      }
    }

    default:
      return state;
  }
}

export default reducer;