const { GET_CREDIT_BALANCE } = require('redux/reseller-constants');

const initialState = {
  creditbalance: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
 
  switch (type) {
    case GET_CREDIT_BALANCE: {
      return {
        ...state,
        creditbalance: payload,
      };
    }
    default:
      return state;
  }
  
};
export default reducer;
