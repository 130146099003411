const { GET_SALES_COMPARISON } = require("redux/admin-constants");

const initialState = {
  salesComparison: [],
}

const reducer = (state = initialState, action) => {
  const {
    type,
    payload
  } = action;

  switch (type) {
    case GET_SALES_COMPARISON: {

      return {
        ...state,
        salesComparison: payload,
      }
    }

    default:
      return state;
  }
}
export default reducer;