const {
    GET_SALES_LIMIT,
    GET_SALES_LIMIT_RESELLER,
    SALES_LIMIT_NOTIFICATION,
    ADD_SALES_LIMIT,
    UPDATE_SALES_LIMIT,
  } = require('redux/admin-constants');

  const initialState = {
    salesLimit: [],
    salesLimitReseller: [],
    sales_limit_notification: {
      message: '',
      isSuccess: 1,
    },
  }; 

  const reducer = (state = initialState, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case GET_SALES_LIMIT_RESELLER: {
        return {
          ...state,
          salesLimitReseller: payload,
        };
      }
      case GET_SALES_LIMIT: {
        return {
          ...state,
          salesLimit: payload,
        };
      }
      case ADD_SALES_LIMIT: {
        return {
          ...state,
          salesLimit:[payload, ...state.salesLimit]
        }
      }
      case UPDATE_SALES_LIMIT: {
        return {
          ...state,
          salesLimit: state.salesLimit.map(item => item.user_id === payload.user_id ? 
            { ...item, ...payload} : item 
          )
        }
      }
      case SALES_LIMIT_NOTIFICATION:
        return { ...state, 
          sales_limit_notification: (payload.message) ? payload : initialState.sales_limit_notification
      }
      default:
        return state;
    }
  };
  
  export default reducer;