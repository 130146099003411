import { GET_MY_ACCOUNT, GET_MY_SALES_HISTORY, GET_CUSTOMER_CONFIG } from "../constants";

const initialState = { 
  myaccount:[],
  customerConfig:{}
}
  
const reducer = (state = initialState, action) => {
  const {
    type,
    payload
  } = action;

  switch (type) {
    case GET_MY_ACCOUNT: {
      return {
        ...state,
        myaccount: payload,
      }
    }

    case GET_MY_SALES_HISTORY: {
      return {
        ...state,
        myrechargehistory: payload,
      }
    }

    case GET_CUSTOMER_CONFIG: {
      return {
        ...state,
        customerConfig: payload,
      }
      }
    default:
        return state;
    }
}

export default reducer;