const { ACTIVATED_USER, INACTIVE_USER, DELETED_INACTIVATE_USER, TEMP_USERS, ACTIVATED_TEMP_USER, TEMP_USER_NOTIFCATION } = require("redux/constants");
  
const initialState = { 
    activated_user: [],
    inactive_user: [],
    temp_user: [],
    tempUserNotification:{
      message: '',
      isSuccess: 1
  },
}
  
const reducer = (state = initialState,  action) => {
  const { type, payload } = action;
  
  switch (type) {

    case ACTIVATED_USER: {
      return {
        ...state,
        activated_user: payload,
      }
    }
  
    case INACTIVE_USER:{
      return {
        ...state,
        inactive_user: payload,
      }
    }

    case DELETED_INACTIVATE_USER:

      return { ...state, 
        inactive_user: state.inactive_user.filter(item => item.usermac_id !== payload.mac_id)
    }

    case TEMP_USERS:{
      return {
        ...state,
        temp_user: payload,
      }
    }

    case ACTIVATED_TEMP_USER:{
      return {
        ...state,
        temp_user: state.temp_user.filter(item => item.tempuser_id !== payload.tempUserId)
      }
    }

    case TEMP_USER_NOTIFCATION:
      return { ...state, 
        tempUserNotification: (payload.message) ? payload : initialState.tempUserNotification
    }
  
    default:
      return state;
    }
  }
  
  export default reducer;