import { COMMON_NOTIFICATION, ON_BACK } from '../constants';

const initialState = {
  common_notification: {
    message: '',
    isSuccess: 1,
  },
  Back: { view: false },
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case COMMON_NOTIFICATION:
      console.log('reducer');
      return {
        ...state,
        common_notification: payload.message
          ? payload
          : initialState.common_notification,
      };
    case ON_BACK:
      console.log(payload);
      return {
        ...state,
        Back: payload.view ? payload : initialState.Back,
      };
    default:
      return state;
  }
};

export default reducer;
