const { GET_RECHARGE_SUMMARY,  GET_CUSTOMERS} = require("redux/reseller-constants");

const initialState = {
  rechargesummary: [],
  customer:[],
 
}

const reducer = (state = initialState, action) => {
  const {
    type,
    payload
  } = action;

  switch (type) {
    case GET_RECHARGE_SUMMARY: {

      return {
        ...state,
        rechargesummary: payload,
      }
    }
    case GET_CUSTOMERS: {
      return {
        ...state,
        customer: payload,
      }
    }

    default:
      return state;
  }
}
export default reducer;