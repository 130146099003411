const {
  UPDATE_RESELLER_CARD_DISCOUNT,
  MANAGE_PRICE_NOTIFICATION,
  CUSTOMER_DATA,
  CARD_DATA
} = require('redux/reseller-constants');

const initialState = {
  ppriceNotification: {
    message: '',
    isSuccess: 1,
  },
};

const reducer = (state = initialState, action) => {
  const {
    type,
    payload
  } = action;

  switch (type) {
    case UPDATE_RESELLER_CARD_DISCOUNT: {
      return {
        ...state,
        updatedManagePrice: payload,
      }
    }

    case MANAGE_PRICE_NOTIFICATION: {
      return {
        ...state,
        priceNotification: payload.message ? payload : initialState.priceNotification,
      };
    }

    case CUSTOMER_DATA: {
      return {
        ...state,
        cutomerData: payload,
      }
    }

    case CARD_DATA: {
      return {
        ...state,
        cardData: payload,
      }
    }

    default:
      return state;
  }
};

export default reducer;