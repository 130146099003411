const { GET_CUSTOMER_BALANCE_LIST } = require("redux/admin-constants");

const initialState = {
  customerBalance: [],
  customerBalance_group: [],
}

const reducer = (state = initialState, action) => {
  const {
    type,
    payload
  } = action;

  switch (type) {
    case GET_CUSTOMER_BALANCE_LIST: {

      return {
        ...state,
        customerBalance: payload,
      }
    }

    default:
      return state;
  }
}
export default reducer;