const {
  GET_CUSTOMERS,
  INSERT_RESELLER_USER,
  UPDATE_RESELLER_USER,
  RESELLER_USER_NOTIFICATION,
  DELETE_RESELLER_USER,
  GET_CUSTOMER_USER,
  RESELLERINACTIVE_USER,
  DELETED_RESELLERINACTIVATE_USER,
} = require('redux/reseller-constants');

const initialState = {
  customers: [],
  customerUsers: [],
  reseller_user_notification: {
    message: '',
    isSuccess: 1,
  },
  inactive_user: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CUSTOMERS: {
      return {
        ...state,
        customers: payload,
      };
    }

    case GET_CUSTOMER_USER: {
      return {
        ...state,
        customerUsers: payload,
      };
    }

    case INSERT_RESELLER_USER: {
      return {
        ...state,
        customerUsers: [payload, ...state.customerUsers],
      };
    }

    case UPDATE_RESELLER_USER: {
      return {
        ...state,
        customerUsers: state.customerUsers.map((item) =>
          item.username === payload.username ? { ...item, ...payload } : item
        ),
      };
    }

    case DELETE_RESELLER_USER: {
      return {
        ...state,
        customerUsers: state.customerUsers.filter(
          (item) => item.user_id !== payload.user_id
        ),
      };
    }

    case RESELLER_USER_NOTIFICATION:
      return {
        ...state,
        reseller_user_notification: payload.message
          ? payload
          : initialState.reseller_user_notification,
      };

    case RESELLERINACTIVE_USER: {
      return {
        ...state,
        inactive_user: payload,
      };
    }
    case DELETED_RESELLERINACTIVATE_USER:
      return {
        ...state,
        inactive_user: state.inactive_user.filter(
          (item) => item.usermac_id !== payload.mac_id
        ),
      };

    default:
      return state;
  }
};

export default reducer;
