import {
  CHECK_CONFIG_LOGIN,
  GET_CUSTOMERS,
  GET_CUSTOMER_CONFIG,
  SAVE_CONFIG,
  GET_CREDIT_HISTORY,
  ADD_NEW_PAYMENT,
  GET_INCHARGE_LIST,
  LOGIN_NOTIFICATION,
  CHANGE_PASSWORD
} from 'redux/admin-constants';

const reducer = (state = {}, action) => {
  const {
    type,
    payload
  } = action;

  switch (type) {
    case CHECK_CONFIG_LOGIN: {
      return {
        ...state,
        checkconfiglogin: payload,
      }
    }
    case LOGIN_NOTIFICATION:
      return { ...state, 
        loginNotification: (payload.message) ? payload : null
    }
    case GET_CUSTOMERS: {
      return {
        ...state,
        getcustomers: payload,
      }
    }
    case GET_CUSTOMER_CONFIG: {
      return {
        ...state,
        getcustomerconfig: payload,
      }
    }
    case SAVE_CONFIG: {
      return {
        ...state,
        saveconfig: payload,
      }
    }
    case GET_CREDIT_HISTORY: {
      return {
        ...state,
        getcredithistory: payload,
      }
    }
    case ADD_NEW_PAYMENT: {
      return {
        ...state,
        addnewpayment: payload,
      }
    }
    case GET_INCHARGE_LIST: {
      return {
        ...state,
        getinchargelist: payload,
      }
    }
    case CHANGE_PASSWORD: {
      return {
        ...state,
        changePassword: payload,
      }
    }
    default:
        return state;
    }
}
export default reducer;