const {
  GET_INVOICE_REPORT,
  CASH_FLOW_REPORT_NOTIFICATION,
  DELETE_INVOICE_REPORT,
} = require('redux/admin-constants');

const initialState = {
  invoiceReport: [],
  deleteInvoiceReport: [],
  invoiceReportNotification: {
    message: '',
    isSuccess: 1,
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_INVOICE_REPORT: {
      return {
        ...state,
        invoiceReport: payload,
      };
    }

    case CASH_FLOW_REPORT_NOTIFICATION: {
      return {
        ...state,
        invoiceReportNotification: payload.message
          ? payload
          : initialState.invoiceReportNotification,
      };
    }
    case DELETE_INVOICE_REPORT: {
      return {
        ...state,
        deleteInvoiceReport: payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
