import { GET_PARTICULAR_CARD, SET_PURCHASE } from '../constants';

const reducer = (state = {}, action) => {
  const {
    type,
    payload
  } = action;

  switch (type) {
    case GET_PARTICULAR_CARD:{
      return {
        ...state,
        getParticularCard: payload,
      }
    }

    case SET_PURCHASE:{
      return {
        ...state,
        purchasedcards: payload,
      }
    }
    default:
      return state;
  }
}
export default reducer;