import {
  GET_USER_PROFILE,
  GET_COUNTRIES,
  UPDATE_PROFILE_DETAILS,
  SELECTED_USER_PROFILE,
  DELETE_USER_PROFILE,
  PROFILE_DETAILS_NOTIFICATION,
  GET_USER_PROFILEDATA,
  GET_ALLUSER_PROFILE,
} from '../constants';

const initialState = {
  countries: [],
  profiles: [],
  profilesData: [],
  selected_profiles: {},
  profile_dettails_notification: {
    message: '',
    isSuccess: 1,
  },
  alluser: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_USER_PROFILE: {
      return {
        ...state,
        profiles: payload,
      };
    }
    case GET_USER_PROFILEDATA: {
      return {
        ...state,
        profilesData: payload,
      };
    }
    case GET_COUNTRIES: {
      return {
        ...state,
        countries: payload,
      };
    }
    case SELECTED_USER_PROFILE: {
      return {
        ...state,
        selected_profiles: payload,
      };
    }

    case UPDATE_PROFILE_DETAILS: {
      return {
        ...state,
        profilesData: state.profilesData.map((item) =>
          item.customer_id === payload.customerId
            ? { ...item, ...payload }
            : item
        ),
      };
    }
    case GET_ALLUSER_PROFILE: {
      return {
        ...state,
        alluser: payload,
      };
    }

    case DELETE_USER_PROFILE: {
      return {
        ...state,
        profiles: state.profiles.filter(
          (item) => item.customer_id !== payload.customerId
        ),
      };
    }

    case PROFILE_DETAILS_NOTIFICATION:
      return {
        ...state,
        profile_dettails_notification: payload.message
          ? payload
          : initialState.profile_dettails_notification,
      };

    default:
      return state;
  }
};

export default reducer;
