import {
    GET_CALL_THROUGH_PRICE,
    PURCHASE_CALL_THROUGH,
    GET_CALL_THROUGH_LIST
  } from '../constants';

  const initialState = {
    callthroughlist: [],
  };
  
const reducer = (state = initialState, action) => {
  const {
    type,
    payload
  } = action;

  switch (type) {
    case GET_CALL_THROUGH_PRICE: {
      return {
        ...state,
        callthroughprice: payload,
      }
    }
    case PURCHASE_CALL_THROUGH:{
      return {
        ...state,
        callthroughpurchase: payload,
      }
    }
    case GET_CALL_THROUGH_LIST: {
      return {
        ...state,
        callthroughlist: payload,
      }
      }
    default:
        return state;
    }
}

export default reducer;