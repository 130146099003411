const {
  GET_ADMIN_USER,
  USER_DETAILS_NOTIFICATION,
  UPDATE_USER_DETAILS,
  INSERT_USER_DETAILS,
  DELETE_USER_DETAILS,
  GET_LOGIN_USER,
  GET_CUSTOMER_USER,
} = require('redux/admin-constants');

const initialState = {
  users: [],
  user_dettails_notification: {
    message: '',
    isSuccess: 1,
  },
  customerUser: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ADMIN_USER: {
      return {
        ...state,
        users: payload,
      };
    }

    case GET_CUSTOMER_USER: {
      return {
        ...state,
        customerUser: payload,
      };
    }

    case INSERT_USER_DETAILS: {
      return {
        ...state,
        users: payload,
      };
    }

    case UPDATE_USER_DETAILS: {
      return {
        ...state,
        users: state.users?.data?.map((item) =>
          item.username === payload.username ? { ...item, ...payload } : item
        ),
      };
    }

    case USER_DETAILS_NOTIFICATION:
      return {
        ...state,
        user_dettails_notification: payload.message
          ? payload
          : initialState.user_dettails_notification,
      };

    case DELETE_USER_DETAILS:
      return {
        ...state,
        customerUser: state.customerUser.filter(
          (item) => item.user_id !== payload.user_id
        ),
      };

    case GET_LOGIN_USER: {
      return {
        ...state,
        loginuser: payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;
