import {
  GET_USER_ALERT,
  INSERT_USER_ALERT,
  UPDATE_USER_ALERT,
  DELETE_USER_ALERT,
  USER_ALERT_NOTIFICATION,
} from 'redux/admin-constants';

const initialState = {
  userAlertMessage: [],
  user_alert_notification: {
    message: '',
    isSuccess: 1,
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_USER_ALERT: {
      return {
        ...state,
        userAlertMessage: payload,
      };
    }

    case USER_ALERT_NOTIFICATION:
      return {
        ...state,
        user_alert_notification: payload.message
          ? payload
          : initialState.user_alert_notification,
      };

    case INSERT_USER_ALERT: {
      return {
        ...state,
        userAlertMessage: [payload, ...state.userAlertMessage],
      };
    }

    case UPDATE_USER_ALERT: {
      return {
        ...state,
        userAlertMessage: state.userAlertMessage.map((item) =>
          item.usr_user_alert_id === payload.usr_user_alert_id
            ? { ...item, ...payload }
            : item
        ),
      };
    }

    case DELETE_USER_ALERT:
      return {
        ...state,
        userAlertMessage: state.userAlertMessage.filter(
          (item) => item.usr_user_alert_id !== payload.alertID
        ),
      };

    default:
      return state;
  }
};

export default reducer;
