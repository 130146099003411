const {
  GET_MANAGE_PRICE,
  UPDATE_MANAGE_PRICE,
  MANAGE_PRICE_NOTIFICATION
} = require('redux/admin-constants');

const initialState = {
  manageprice: [],
  managepricenotification: {
    message: '',
    isSuccess: 1,
  },
}; 

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_MANAGE_PRICE: {
      return {
        ...state,
        manageprice: payload,
      };
    }

    case UPDATE_MANAGE_PRICE: {
      return {
        ...state,
        updatedmanageprice: payload,
      }
    }

    case MANAGE_PRICE_NOTIFICATION: {
      return {
        ...state,
        managepricenotification: payload.message
          ? payload
          : initialState.managepricenotification,
      };
    }
   
    default:
      return state;
  }
};

export default reducer;