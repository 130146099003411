import {
  DELETE_RESELLERTICKET_PRICE,
  GET_RESELLERTICKET_PRICE,
  UPDATE_RESELLERTICKET_PRICE,
} from 'redux/constants';

const reducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_RESELLERTICKET_PRICE: {
      return {
        ...state,
        ticketpricedata: payload,
      };
    }
    case UPDATE_RESELLERTICKET_PRICE: {
      return {
        ...state,
        updatedticketprice: payload,
      };
    }
    case DELETE_RESELLERTICKET_PRICE: {
      return {
        ...state,
        deleteticketprice: payload,
        // ticketpricedata: state.ticketpricedata.filter(
        //   (item) => item.prc_customer_priceid !== payload.priceId
        // ),
      };
    }
    default:
      return state;
  }
};

export default reducer;
