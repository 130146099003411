import {
  GET_MY_ACCOUNTS,
  GET_CASHFLOW_REPORT,
  GET_CASHFLOW_PDF,
} from '../../constants';

const reducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_MY_ACCOUNTS: {
      return {
        ...state,
        myaccount: payload,
      };
    }
    case GET_CASHFLOW_REPORT: {
      return {
        ...state,
        cashflowreport: payload,
      };
    }
    case GET_CASHFLOW_PDF: {
      return {
        ...state,
        cashflowpdf: payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
