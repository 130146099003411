const { GET_MARQUEE_LIST, MARQUEE_DETAILS_NOTIFICATION, INSERT_MARQUEE_DETAILS, UPDATE_MARQUEE_DETAILS, DELETE_MARQUEE_DETAILS, GET_CARD_MARQUEE_LIST} = require("redux/admin-constants");

const initialState = {
  marqueeOption: [],
  cardMarqueeOption: [],
  marquee_dettails_notification: {
    message: '',
    isSuccess: 1
  }
}

const reducer = (state = initialState, action) => {
  const {
    type,
    payload
  } = action;

  switch (type) {
    case GET_MARQUEE_LIST: {
      return {
        ...state,
        marqueeOption: payload,
      }
    }

    case GET_CARD_MARQUEE_LIST: {
      return {
        ...state,
        cardMarqueeOption: payload,
      }
    }

    case INSERT_MARQUEE_DETAILS: {
      return { ...state, marqueeOption: [payload, ...state.marqueeOption] }
    }
   
    case UPDATE_MARQUEE_DETAILS: {
      { 
        return {
          ...state,
          marqueeOption:
            state.marqueeOption.map(item => item.marquee_id === payload.marquee_id ?
              { ...item, ...payload } : item
            )
        }
      }
    }

    case MARQUEE_DETAILS_NOTIFICATION:
      return {
        ...state,
        marquee_dettails_notification: (payload.message) ? payload : initialState.marquee_dettails_notification
      }

    case DELETE_MARQUEE_DETAILS:
      return {
        ...state,
        marqueeOption: state.marqueeOption.filter(item => item.marquee_id !== payload.marqueeId)
      }

    default:
      return state;
  }
}

export default reducer;