const { GET_MF_SALES_REPORT } = require("redux/admin-constants");

const initialState = {
  mfsalesreport: [],
}

const reducer = (state = initialState, action) => {
  const {
    type,
    payload
  } = action;

  switch (type) {
    case GET_MF_SALES_REPORT: {

      return {
        ...state,
        mfsalesreport: payload,
      }
    }

    default:
      return state;
  }
}
export default reducer;