import {
  GET_TICKET_PRICE,
  UPDATE_TICKET_PRICE,
  DELETE_TICKET_PRICE,
} from 'redux/admin-constants';

const reducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_TICKET_PRICE: {
      return {
        ...state,
        ticketpricedata: payload,
      };
    }
    case UPDATE_TICKET_PRICE: {
      return {
        ...state,
        updatedticketprice: payload,
      };
    }
    case DELETE_TICKET_PRICE: {
      return {
        ...state,
        deleteadminticketprice: payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
