import {
    USER_REGISTER_NOTIFICATION,
  } from '../../admin-constants';
  
  const initialState = { 
    registerNotification:{
        message: '',
        isSuccess: 1
    }
  }
  
  const reducer = (state = initialState, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case USER_REGISTER_NOTIFICATION:
        return { ...state, 
          registerNotification: (payload.message) ? payload : initialState.registerNotification
      }
      default:
        return state;
    }
  }
  
  export default reducer;