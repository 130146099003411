import {
  GET_SENT_MAIL,
  GET_DELETE_MAIL,
  GET_INBOX_MAIL,
  GET_EMAIL_IDS,
  POST_EMAIL,
  DELETE_EMAIL,
  UNDO_EMAIL
} from 'redux/admin-constants';

const reducer = (state = {}, action) => {
const {
  type,
  payload
} = action;

switch (type) {
  case GET_SENT_MAIL: {
    return {
      ...state,
      sentMail: payload.data,
      allSentMail: payload.data
    }
  }

  case GET_DELETE_MAIL:{
    return {
      ...state,
      deleteMail: payload.data,
      allDeleteMail:payload.data,
    }
  }

  case GET_INBOX_MAIL:{
    return {
      ...state,
      inboxMail: payload.data,
      allInboxMail:payload.data,
    }
  }

  case GET_EMAIL_IDS:{
    return {
      ...state,
      emailIds: payload.data,
    }
  }

  case POST_EMAIL:{
    return {
      ...state,
      postedEmail: payload,
    }
  }

  case DELETE_EMAIL:{
    if(payload[1].isSend && payload[1].isDeleted===0){
      return {
        ...state,
        deletedEmail: payload[0],
        sentMail: state.allSentMail.filter(item => !payload[1].inboxIds.map((item)=>item.mailIDs).includes(item.mgm_mail_inbox_id))
        }
    }
    if(payload[1].isInbox){
      return {
        ...state,
        deletedEmail: payload[0],
        inboxMail: state.allInboxMail.filter(item => !payload[1].inboxIds.map((item)=>item.mailIDs).includes(item.mgm_mail_inbox_id))
        }
    }
    if(payload[1].isDeleted){
      return {
        ...state,
        deletedEmail: payload[0],
        deleteMail: state.allDeleteMail.filter(item => !payload[1].inboxIds.map((item)=>item.mailIDs).includes(item.mgm_mail_inbox_id))
        }
    }
  }

  case UNDO_EMAIL:{
    return {
      ...state,
      undoEmail: payload[0],
      sentMail: state.allSentMail,
      inboxMail: state.allInboxMail,
      deleteMail: state.allDeleteMail
    }
  }

  default:
    return state;
}
}

export default reducer;