const {
    GET_PROFIT_REPORT,
    PROFIT_REPORT_NOTIFICATION,
  } = require('redux/admin-constants');

  const initialState = {
    profitReport: [],
    profitReportNotification: {
      message: '',
      isSuccess: 1,
    },
  }; 

  const reducer = (state = initialState, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case GET_PROFIT_REPORT: {
        return {
          ...state,
          profitReport: payload,
        };
      }
     
      case PROFIT_REPORT_NOTIFICATION: {
        return {
          ...state,
          profitReportNotification: payload.message
            ? payload
            : initialState.profitReportNotification,
        };
      }
      default:
        return state;
    }
  };
  
  export default reducer;