const {
    GET_STOCK_REPORT,
    STOCK_REPORT_NOTIFICATION,
  } = require('redux/admin-constants');

  const initialState = {
    stockReport: [],
    stockReportNotification: {
      message: '',
      isSuccess: 1,
    },
  }; 

  const reducer = (state = initialState, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case GET_STOCK_REPORT: {
        return {
          ...state,
          stockReport: payload,
        };
      }
     
      case STOCK_REPORT_NOTIFICATION: {
        return {
          ...state,
          stockReportNotification: payload.message
            ? payload
            : initialState.stockReportNotification,
        };
      }
      default:
        return state;
    }
  };
  
  export default reducer;