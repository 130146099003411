const {
  GET_INVOICE_LIST,
  INVOICE_LIST_NOTIFICATION,
  GENERATE_INVOICE_LIST,
} = require('redux/admin-constants');

const initialState = {
  invoiceList: [],
  invoiceListNotification: {
    message: '',
    isSuccess: 1,
  },
  generate: '',
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_INVOICE_LIST: {
      return {
        ...state,
        invoiceList: payload,
      };
    }

    case INVOICE_LIST_NOTIFICATION: {
      return {
        ...state,
        invoiceListNotification: payload.message
          ? payload
          : initialState.invoiceListNotification,
      };
    }
    case GENERATE_INVOICE_LIST: {
      return { ...state, generate: payload };
    }
    default:
      return state;
  }
};

export default reducer;
