import {
  GET_RESELLER_CUSTOMERS,
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_NOTIFICATION,
  ENABLE_RESELLER_CUSTOMER,
  UPDATE_RESELLER_CUSTOMER,
  GET_CUSTOMER_CARDS,
  CHANGE_CARD_STATUS,
  USER_REGISTER_NOTIFICATION,
} from 'redux/reseller-constants';

const initialState = {
  countries: [],
  customers: [],
  deleteCustomerNotification: {
    message: '',
    isSuccess: 1,
  },
  registerNotification: {
    message: '',
    isSuccess: 1,
  },
  customerCards: [],
  changeStatus: 0,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_RESELLER_CUSTOMERS: {
      return {
        ...state,
        customers: payload,
      };
    }
    case DELETE_CUSTOMER: {
      return {
        ...state,
        customers: state.customers.filter(
          (item) => item.customer_id !== payload.customerId
        ),
      };
    }
    case DELETE_CUSTOMER_NOTIFICATION:
      return {
        ...state,
        deleteCustomerNotification: payload.message
          ? payload
          : initialState.deleteCustomerNotification,
      };
    case ENABLE_RESELLER_CUSTOMER:
      return {
        ...state,
        customers: state.customers.map((item) => ({
          ...item,
          active:
            item.customer_id === payload.customerId
              ? payload.status
              : item.active,
        })),
      };
    case UPDATE_RESELLER_CUSTOMER:
      return {
        ...state,
        customers: state.customers.map((item) =>
          item.customer_id === parseInt(payload.customer_id)
            ? {
                ...item,
                ...payload,
              }
            : item
        ),
      };
    case GET_CUSTOMER_CARDS:
      return {
        ...state,
        customerCards: payload,
      };
    case CHANGE_CARD_STATUS:
      return {
        ...state,
        changeStatus: payload,
      };
    case USER_REGISTER_NOTIFICATION:
      return {
        ...state,
        registerNotification: payload.message
          ? payload
          : initialState.registerNotification,
      };
    default:
      return state;
  }
};

export default reducer;
