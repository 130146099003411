const { GET_MY_ACCOUNT, GET_MY_ACCOUNT_PRINT } = require('redux/admin-constants');

  const initialState = {
    myaccount: [],
    print_report: [],
  }; 

  const reducer = (state = initialState, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case GET_MY_ACCOUNT: {
        return {
          ...state,
          myaccount: payload,
        };
      }

      case GET_MY_ACCOUNT_PRINT:
      return {
        ...state,
        print_report: payload
      }

      default:
        return state;
    }
  };
  
  export default reducer;