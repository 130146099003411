/* cards */
export const GET_CARD_LIST = 'get_card_list';
export const GET_CARD_TYPE = 'get_card_type';
export const CARD_DETAILS_NOTIFICATION = 'card_details_notification';
export const INSERT_CARD_DETAILS = 'insert_card_details';
export const UPDATE_CARD_DETAILS = 'update_card_details';
export const DELETE_CARD_DETAILS = 'delete_card_details';
export const GET_PRODUCT_GROUP = 'get_product_group';

export const GET_CARD_DENOMINATION_LIST = 'get_card_denomination_list';
export const CARD_DENOMINATION_NOTIFICATION = 'card_denomination_notification';
export const UPDATE_CARD_DENOMINATION_LIST = 'update_card_denomination_list';
export const DELETE_CARD_DENOMINATION_LIST = 'delete_card_denomination_list';
export const GET_PRICE_LIST = 'get_price_list';

export const BATCH_FORMAT_DATA = 'batch_format_data';
export const BATCH_FORMAT_NOTIFICATION = 'batch_format_notification';
export const BATCH_CARD_NOTIFICATION = 'batch_card_notification';
export const BATCH_SUMMARY_DATA = 'batch_summary_data';
export const PIN_BATCH_STATUS = 'pin_batch_status';
export const MANAGE_PIN_REMOVE = 'manage_pin_remove';
export const MANAGE_REMOVE = 'manage_remove';
export const BATCH_PIN_NOTIFICATION = 'batch_pin_notification';
export const BATCH_DENO_INFO = 'batch_deno_info';
export const BATCH_PIN__BLOCK_NOTIFICATION = 'batch_pin__block_notification';
export const GET_CARD_SEARCH = 'get_card_search';

/* configuration*/
export const CHECK_CONFIG_LOGIN = 'check_config_login';
export const GET_CUSTOMERS = 'get_customers';
export const GET_CUSTOMER_CONFIG = 'get_customer_config';
export const SAVE_CONFIG = 'save_config';
export const GET_CREDIT_HISTORY = 'get_credit_history';
export const GET_CREDIT_HISTORY_SORTED = 'get_credit_history_sorted';
export const ADD_NEW_PAYMENT = 'add_new_payment';
export const GET_INCHARGE_LIST = 'get_incharge_list';
export const LOGIN_NOTIFICATION = 'login_notification';

/* Guest Admin */
export const GET_GUEST_ADMIN_LIST = 'get_guest_admin_list';
export const GUEST_ADMIN_DETAILS_NOTIFICATION =
  'guest_admin_details_notification';
export const INSERT_GUEST_ADMIN_DETAILS = 'insert_guest_admin_details';
export const UPDATE_GUEST_ADMIN_DETAILS = 'update_guest_admin_details';
export const DELETE_GUEST_ADMIN_DETAILS = 'delete_guest_admin_details';

/* Bericht */
export const GET_CUSTOMER_BALANCE_LIST = 'get_customer_balance_list';
export const GET_CUSTOMER_BALANCE_GROUP = 'get_customer_balance_group';

/*Incharge */
export const GET_INCHARGE_LIST1 = 'get_incharge_list1';
export const INSERT_INCHARGE_DETAILS = 'insert_incharge_details';
export const UPDATE_INCHARGE_DETAILS = 'update_incharge_details';
export const DELETE_INCHARGE_DETAILS = 'delete_incharge_details';
export const INCHARGE_LIST_NOTIFICATION = 'incharge_list_notification';

/*Ticket price*/

export const GET_TICKET_PRICE = 'get_ticket_price';
export const UPDATE_TICKET_PRICE = 'update_ticket_price';
export const DELETE_TICKET_PRICE = 'delete_ticket_price';
export const TICKET_PRICE_NOTIFICATION = 'ticket_price_notification';

/*Sales report*/
export const GET_SALES_REPORT = 'get_sales_report';
export const SALES_REPORT_NOTIFICATION = 'sales_report_notification';

/* Sales report cardwise */
export const GET_SALES_REPORT_CARDWISE = 'get_sales_report_cardwise';

/* stock report */
export const GET_STOCK_REPORT = 'get_stock_report';
export const STOCK_REPORT_NOTIFICATION = 'stock_report_notification';

/* recharge summary */
export const GET_RECHARGE_SUMMARY = 'get_recharge_summary';
export const GET_RECHARGE_SUMMARY_PRINT = 'get_recharge_summary_print';

/* profit report */
export const GET_PROFIT_REPORT = 'get_stock_report';
export const REPORT_REPORT_NOTIFICATION = 'stock_report_notification';

/* my account */

export const GET_MY_ACCOUNT = 'get_my_account';
export const GET_MY_ACCOUNT_PRINT = 'get_my_account_print';
/* arascom report */
export const GET_ARAS_CARDS = 'get_aras_cards';
export const ADD_ARAS_CARDS = 'add_aras_cards';
export const UPDATE_ARAS_CARDS = 'update_aras_cards';
export const AUTO_ARAS_PIN_DOWNLOAD = 'auto_aras_pin_download';
export const ARAS_NOTIFICATION = 'aras_notification';
export const ARAS_REPORT = 'aras_report';

export const PROFIT_REPORT_NOTIFICATION = 'profit_report_notification';

/* open balance report */
export const GET_OPEN_BALANCE_REPORT = 'get_open_balance_report';
export const OPEN_BALANCE_REPORT_NOTIFICATION =
  'open_balance_report_notification';

/* Cash report */
export const GET_CASH_FLOW_REPORT = 'get_cash_flow_report';
export const CASH_FLOW_REPORT_NOTIFICATION = 'cash_flow_report_notification';

/* Invoice report */
export const GET_INVOICE_REPORT = 'get_invoice_report';
export const INVOICE_REPORT_NOTIFICATION = 'invoice_report_notification';
export const DELETE_INVOICE_REPORT = 'delete_invoice_report';

/* Invoice Genearte */
export const GET_INVOICE_LIST = 'get_invoice_list';
export const INVOICE_LIST_NOTIFICATION = 'invoice_list_notification';
export const GENERATE_INVOICE_LIST = 'generate_invoice_list';
/* User Admin */
export const GET_LOGIN_USER = 'get_login_user';
export const GET_ADMIN_USER = 'get_admin_user';
export const USER_DETAILS_NOTIFICATION = 'user_details_notification';
export const INSERT_USER_DETAILS = 'insert_user_details';
export const UPDATE_USER_DETAILS = 'update_user_details';
export const DELETE_USER_DETAILS = 'delete_user_details';
export const GET_CUSTOMER_USER = 'get_customer_user';

/* Sales Comparison */

export const GET_SALES_COMPARISON = 'get_sales_comparison';
export const GET_MARQUEE_OPTION = 'get_marquee_option';

/*pin delete */
export const GET_PIN_DELETE = 'get_pin_delete';
/* manage price report*/

export const GET_MANAGE_PRICE = 'get_manage_price';
export const UPDATE_MANAGE_PRICE = 'update_manage_price';
export const MANAGE_PRICE_NOTIFICATION = 'manage_price_notification';
/* call through list*/

export const GET_CALL_THROUGH_LIST = 'get_call_through_list';

export const DELETE_CALL_THROUGH = 'delete_call_through';

export const UPDATE_CALL_THROUGH_DETAILS = 'update_call_through_details';
export const INSERT_CALL_THROUGH_DETAILS = 'insert_Call_through_details';
export const CALL_THROUGH_LIST_NOTIFICATION = 'call_through_list_notification';
/* User web service */

export const GET_WEB_USER = 'get_web_user';
// export const WEBUSER_DETAILS_NOTIFICATION = 'webuser_details_notification';
export const INSERT_WEBUSER_DETAILS = 'insert_webuser_details';
export const UPDATE_WEBUSER_DETAILS = 'update_webuser_details';
export const DELETE_WEBUSER_LIST = 'delete_user_list';
export const UPDATE_WEBUSER_LIST = 'update_user_list';
export const INSERT_IP_ADDRESS = 'insert_ip_address';
export const GET_IP_ADDRESS = 'get_ip_address';
export const DELETE_IP_ADDRESS = 'delete_ip_address';

/* Marquee Option */

export const GET_MARQUEE_LIST = 'get_marquee_list';
export const GET_CARD_MARQUEE_LIST = 'get_card_marquee_list';
export const MARQUEE_DETAILS_NOTIFICATION = 'marquee_details_notification';
export const INSERT_MARQUEE_DETAILS = 'insert_marquee_details';
export const UPDATE_MARQUEE_DETAILS = 'update_marquee_details';
export const DELETE_MARQUEE_DETAILS = 'delete_marquee_details';

/* MF Bus Sales Report */
export const GET_MF_SALES_REPORT = 'get_mf_sales_report';
export const GET_CASH_FLOW_PDF = 'get_cash_flow_pdf';

/* International price upload */
export const GET_INTERNATIONAL_PRICE = 'get_international_price';
export const UPDATE_INTERNATIONAL_PRICE = 'update_international_price';
export const PRICE_UPLOAD_NOTIFICATION = 'price_upload_notification';

/* Mail option */

export const GET_INBOX_MAIL = 'get_inbox_mail';
export const GET_SENT_MAIL = 'get_sent_mail';
export const GET_DELETE_MAIL = 'get_delete_mail';
export const GET_EMAIL_IDS = 'get_email_ids';
export const POST_EMAIL = 'post_email';
export const DELETE_EMAIL = 'delete_email';
export const UNDO_EMAIL = 'undo_email';

/* User Notification */
export const GET_USER_ALERT = 'get_user_alert';
export const INSERT_USER_ALERT = 'insert_user_alert';
export const UPDATE_USER_ALERT = 'update_user_alert';
export const DELETE_USER_ALERT = 'delete_user_alert';
export const USER_ALERT_NOTIFICATION = 'user_alert_notification';

/* start page */
export const GET_LANDING_POSTER = 'get_landing_poster';
export const INSERT_POSTER_DETAILS = 'insert_poster_details';
export const POSTER_DETAILS_NOTIFICATION = 'poster_details_notification';
export const DELETE_POSTER_DETAILS = 'delete_poster_details';

/* admin home */
export const GET_HOME_CONTENT = 'get_home_content';
export const CHANGE_PASSWORD = 'change_password';

/* user registration */
export const USER_REGISTER_NOTIFICATION = 'user_register_notification';

/*Sales limit*/
export const GET_SALES_LIMIT_RESELLER = 'get_sales_limit_reseller';
export const GET_SALES_LIMIT = 'get_sales_limit';
export const ADD_SALES_LIMIT = 'add_sales_limit';
export const UPDATE_SALES_LIMIT = 'update_sales_limit';
export const SALES_LIMIT_NOTIFICATION = 'sales_limit_notification';

/*User Menu */
export const TEMP_USERS = 'temp_users';
export const ACTIVATED_TEMP_USER = 'activated_temp_users';
export const TEMP_USER_NOTIFCATION = 'temp_user_notification';
export const GET_ALL_MENUS = 'get_all_menus';
export const INSERT_USER_MENU = 'insert_user_menu';
export const UPDATE_USER_MENU = 'update_user_menu';
export const GET_USER_MENU = 'get_user_menu';
export const DELETE_USER_MENU = 'delete_user_menu';
export const DELETE_USER_LIST = 'delete_user_list';
/* User Work Group Management */
export const INSERT_WORKGROUP = 'insert_workgroup';
export const UPDATE_WORKGROUP = 'update_workgroup';
export const GET_WORKGROUP = 'get_workgroup';
export const DELETE_WORKGROUP = 'delete_workgroup';
export const GET_WORKGROUP_NAME = 'get_workgroup_name';

/* User Work Group Management */
export const INSERT_WORKGROUP_MENU = 'insert_workgroup_menu';
export const UPDATE_WORKGROUP_MENU = 'update_workgroup_menu';
export const GET_WORKGROUP_MENU = 'get_workgroup_menu';
export const DELETE_WORKGROUP_MENU = 'delete_workgroup_menu';
export const GET_WORKGROUP_MENU_LIST = 'get_workgroup_menu_list';

export const INSERT_USER_WORKGROUP = 'insert_user_workgroup';
export const UPDATE_USER_WORKGROUP = 'update_user_workgroup';
export const GET_USER_WORKGROUP = 'get_user_workgroup';
export const DELETE_USER_WORKGROUP = 'delete_user_workgroup';
export const GET_USER_TYPE = 'get_user_type';
/*Sub domain */
export const GET_SUBDOMAIN_LIST = 'get_subdomain_list';
export const MANAGE_SUBDOMAIN = 'manage_subdomain';
export const SUBDOMAIN_NOTIFICATION = 'subdomain_notification';
export const UPDATE_SUBDOMAIN_DETAILS = 'update_subdomain_details';
export const INSERT_SUBDOMAIN_DETAILS = 'insert_subdomain_details';
export const DELETE_SUBDOMAIN_DETAILS = 'delete_subdomain_details';
export const GET_RETAILER_LIST = 'get_retailer_list';
export const ASSIGN_RETAILER_DATA = 'assign_retailer_data';
