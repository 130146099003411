const {
  GET_INVOICE_LIST,
  DELETE_INVOICE_REPORT_RESELLER,
} = require('redux/reseller-constants');

const initialState = {
  invoiceList: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_INVOICE_LIST: {
      return {
        ...state,
        invoiceList: payload,
      };
    }
    case DELETE_INVOICE_REPORT_RESELLER: {
      return {
        ...state,
        deleteInvoiceReport: payload,
      };
    }
    default:
      return state;
  }
};
export default reducer;
