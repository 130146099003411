import { GET_SUPPLIER } from 'redux/constants';

const initialState = { 
  suppliers:[],

}
const reducer = (state = initialState,  action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SUPPLIER: {
      return {
        ...state,
        suppliers: payload,
      }
    }
  default:
    return state;
  }
}

export default reducer;