import {
    GET_MOBILE_DETAILS,
    GET_RECHARGE_DETAILS
  } from '../constants';
  
const reducer = (state = {}, action) => {
  const {
    type,
    payload
  } = action;

  switch (type) {
    case GET_MOBILE_DETAILS: {
      return {
        ...state,
        mobiledetails: payload,
      }
    }
    case GET_RECHARGE_DETAILS:{
      return {
        ...state,
        rechargedetails: payload,
      }
    }
    default:
        return state;
    }
}

export default reducer;