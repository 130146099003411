import {
  CHANGE_PASSWORD,
  LOGIN_NOTIFICATION,
  SLIDER_POSTERS,
  ADMIN_LOGIN_DETAILS,
  RESET_PASSWORD
} from '../constants';

const initialState = { 
  loginNotification:{
      message: '',
      isSuccess: 1
  },
  admin_login_data:{},
  slider_poster: [],
  resetPasswordNotification: {}
}

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    
    case LOGIN_NOTIFICATION:
      return { ...state, 
        loginNotification: (payload.message) ? payload : initialState.loginNotification
    }

    case CHANGE_PASSWORD: {
      return {
        ...state,
        changePassword: payload,
      }
    }

    case SLIDER_POSTERS: {
      return {
        ...state,
        slider_poster: payload,
      }
    }

    case ADMIN_LOGIN_DETAILS: {
      return {
        ...state,
        admin_login_data: payload,
      }
    }

    case RESET_PASSWORD: {
      return {
        ...state,
        resetPasswordNotification: payload,
      }
    }

    default:
      return state;
  }
}

export default reducer;