// reseller cards
export const GET_RESELLER_CARDS = 'get_reseller_cards';
export const UPDATE_RESELLER_CARDS = 'update_reseller_cards';
export const DELETE_CARD = 'delete_cards';
export const DELETE_CARD_NOTIFICATION = 'delete_card_notification';
export const UPDATE_RESELLER_CARD = 'update_reseller_card';
export const GET_RESELLERPRICE_LIST = 'get_reseller_price_list';
// profile
export const GET_RESELLER_CUSTOMERS = 'get_reseller_customers';
export const DELETE_CUSTOMER = 'delete_customer';
export const DELETE_CUSTOMER_NOTIFICATION = 'delete_customer_notification';
export const ENABLE_RESELLER_CUSTOMER = 'enable_reseller_customer';
export const UPDATE_RESELLER_CUSTOMER = 'update_reseller_customer';

// price management
export const UPDATE_RESELLER_CARD_DISCOUNT = 'update_reseller_card_discount';
export const MANAGE_PRICE_NOTIFICATION = 'manage_price_notification';
export const GET_CUSTOMER_CARDS = 'get_customer_cards';
export const CHANGE_CARD_STATUS = 'change_card_status';
export const CUSTOMER_DATA = 'customer_data';
export const CARD_DATA = 'card_data';

//Recharge Summary
export const GET_RECHARGE_SUMMARY = 'recharge-summary';

//Credit Balance
export const GET_CREDIT_BALANCE = 'credit_balance';

//Open Balance
export const GET_OPEN_BALANCE = 'open_balance';

export const GET_SALES_REPORT = 'get_sales_report';
export const GET_CARD_DENOMINATION_LIST = 'get_card_denomination_list';

// Sales Comparison
export const GET_SALES_COMPARISON = 'get_sales_comparison';

// Cash report
export const GET_CASH_FLOW_REPORT = 'get_cash_flow_report';
export const GET_CASH_FLOW_PDF = 'get_cash_flow_pdf';
//user
export const GET_CUSTOMERS = 'get_customers';
export const GET_CUSTOMER_USER = 'get_customer_user';
export const INSERT_RESELLER_USER = 'insert_reseller_user';
export const UPDATE_RESELLER_USER = 'update_reseller_user';
export const RESELLER_USER_NOTIFICATION = 'reseller_user_notification';
export const DELETE_RESELLER_USER = 'delete_reseller_user';
export const RESELLERINACTIVE_USER = 'reseller_inactive_user';
export const DELETED_RESELLERINACTIVATE_USER = 'reseller_inactive_user';
export const UPDATE_RESELLER_STATUS = 'update_reseller_status';

//Profit Report
export const GET_PROFIT_REPORT = 'get_profit_report';
export const PROFIT_REPORT_NOTIFICATION = 'profit_report_notification';

//invoice generate
export const GET_INVOICE_LIST = 'get_invoice_list';
export const INVOICE_LIST_NOTIFICATION = 'invoice_list_notification';
export const DELETE_INVOICE_REPORT_RESELLER =
  'delete_invoice_reporter_reseller';

//marquee option
export const GET_MARQUEE_OPTION = 'get_marquee_option';
export const GET_CARD_MARQUEE_LIST = 'get_card_marquee_list';
export const MARQUEE_DETAILS_NOTIFICATION = 'marquee_details_notification';
export const INSERT_MARQUEE_DETAILS = 'insert_marquee_details';
export const UPDATE_RESELLER_MARQUEE_DETAILS = 'update_marquee_details';
export const DELETE_MARQUEE_DETAILS = 'delete_marquee_details';

/* reseller home */
export const GET_HOME_CONTENT = 'get_home_content';
export const CHANGE_PASSWORD = 'change_password';

/* user registration */
export const USER_REGISTER_NOTIFICATION = 'user_register_notification';

/*Sales limit*/
export const GET_SALES_LIMIT = 'get_sales_limit';
export const ADD_SALES_LIMIT = 'add_sales_limit';
export const UPDATE_SALES_LIMIT = 'update_sales_limit';
export const SALES_LIMIT_NOTIFICATION = 'sales_limit_notification';
