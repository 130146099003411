/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';
/*Commons */
export const COMMON_NOTIFICATION = 'common_notification';
export const ON_BACK = 'onBack';
/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/* auth */
export const LOGIN_NOTIFICATION = 'login_notification';
export const CHANGE_PASSWORD = 'change_password';
export const ADMIN_LOGIN_DETAILS = 'admin_login_details';
export const RESET_PASSWORD = 'reset_password';

/* cards */
export const GET_CARDS = 'get_cards';
export const GET_FAV_CARDS = 'get_fav_cards';
export const GET_NEW_CARDS = 'get_new_cards';
export const GET_FILTERED_CARDS = 'get_filtered_cards';
export const SET_PURCHASE = 'set_purchase';
export const GET_PARTICULAR_CARD = 'get_particular_card';
export const FAV_CARDS_STATUS = 'fav_cards_status';
export const ONLINECARD_NOTIFICATION = 'onlinecard_notification';

/* marquee */
export const GET_MARQUEE = 'get_marquee';

/* my account */
export const GET_MY_ACCOUNT = 'get_my_account';
export const GET_MY_SALES_HISTORY = 'get_my_sales_history';
export const GET_CUSTOMER_CONFIG = 'get_customer_config';

/* direct recharge */
export const GET_DIRECT_RECHARGE_CARDS = 'get_direct_recharge_cards';
export const GET_DIRECT_RECHARGE_CARDS_BY_PROVIDER_ID =
  'get_direct_recharge_cards_by_provider_id';
export const SET_DIRECT_RECHARGE_CARDS = 'set_direct_recharge_cards';
export const DIRECT_RECHARGE_NOTIFICATION = 'direct_recharge_notification';

/* sales history today */
export const GET_SALES_HISTORY_TODAY = 'get_sales_history_today';

/* international recharge */
export const GET_MOBILE_DETAILS = 'get_mobile_details';
export const GET_RECHARGE_DETAILS = 'get_recharge_details';

/* flix bus  */
export const GET_BUS_STATION = 'get_bus_station';
export const POST_FLIX_BUS_SEARCH = 'post_flix_bus_search';
export const SET_DEPARTURE_CART = 'set_departure_cart';
export const SET_RETURN_CART = 'set_return_cart';
export const GET_CART = 'get_cart';
export const GET_PASSENGER = 'get_passenger';
export const SAVE_PASSENGER = 'save_passenger';
export const GET_LUGGAGE = 'get_luggage';
export const MANAGE_LUGGAGE = 'manage_luggage';
export const MANAGE_VOUCHER = 'manage_voucher';
export const FLIXBUS_PAYMENT_DETAILS = 'flix_bus_payment_details';

export const GET_SUPPLIER = 'get_supplier';

/* call through */
export const GET_CALL_THROUGH_PRICE = 'get_call_through_price';
export const PURCHASE_CALL_THROUGH = 'purchage_call_through';
export const GET_CALL_THROUGH_LIST = 'get_call_through_list';

/* profile */
export const GET_USER_PROFILE = 'get_user_profile';
export const GET_USER_PROFILEDATA = 'get_user_profiledata';
export const GET_ALLUSER_PROFILE = 'get_alluser_profiledata';
export const GET_COUNTRIES = 'get_countries';
export const UPDATE_PROFILE_DETAILS = 'update_profile_details';
export const PROFILE_DETAILS_NOTIFICATION = 'profile_details_notification';
export const DELETE_USER_PROFILE = 'delete_user_profile';
export const SELECTED_USER_PROFILE = 'selected_user_profile';

// customer admin //

/* my account */
export const GET_MY_ACCOUNTS = 'get_my_accounts';
export const GET_CASHFLOW_REPORT = 'get_cashflow_report';
export const GET_CASHFLOW_PDF = 'get_Cashflow_pdf';

/*customer user account */
export const GET_LOGIN_USER = 'get_login_user';
export const GET_CUSTOMER_USER = 'get_customer_user';
export const USER_DETAILS_NOTIFICATION = 'user_details_notification';
export const INSERT_USER_DETAILS = 'insert_user_details';
export const UPDATE_USER_DETAILS = 'update_user_details';
export const DELETE_USER_DETAILS = 'delete_user_details';
export const GET_CUSTOMER_USER_SALES = 'get_customer_user_sales';
export const GET_CUSTOMER_INVOICE_REPORT = 'get_customer_invoice_report';
export const REPRINT_DATA = 'reprint_data';
export const DELETE_CUSTOMER_INVOICE_REPORT = 'delete_customer_invoice_report';

/*Price Management */
export const GET_TICKET_PRICE = 'get_ticket_price';
export const DELETE_RESELLERTICKET_PRICE = 'delete_ticket_price';
export const GET_RESELLERTICKET_PRICE = 'get_resellerticket_price';
export const UPDATE_RESELLERTICKET_PRICE = 'update_resellerticket_price';
/*Bulk Purchase*/
export const GET_PURCHASE_CARDS = 'get_purchase_cards';
export const SLIDER_POSTERS = 'slider_posters';
export const INACTIVE_USER = 'inactive_user';
export const ACTIVATED_USER = 'activated_user';
export const DELETED_INACTIVATE_USER = 'deleted_activated_user';

export const TEMP_USERS = 'temp_users';
export const ACTIVATED_TEMP_USER = 'activated_temp_users';
export const TEMP_USER_NOTIFCATION = 'temp_user_notification';
