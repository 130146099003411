import {
  GET_MARQUEE_OPTION,
  MARQUEE_DETAILS_NOTIFICATION, 
  INSERT_MARQUEE_DETAILS, 
  UPDATE_RESELLER_MARQUEE_DETAILS, 
  DELETE_MARQUEE_DETAILS, 
  GET_CARD_MARQUEE_LIST
} from "redux/reseller-constants";

const initialState = {
  marquee: [],
  marquee_dettails_notification: {
    message: '',
    isSuccess: 1
  }
}
  
const reducer = (state = {}, action) => {
  const {
    type,
    payload
  } = action;

  switch (type) {
    case GET_MARQUEE_OPTION: {
      return {
        ...state,
        marquee: payload,
      }
    }
    case INSERT_MARQUEE_DETAILS: {
      return { ...state, marqueeOption: [payload, ...state.marqueeOption] }
    }

    case UPDATE_RESELLER_MARQUEE_DETAILS: {
      {
        return {
          ...state,
          marquee:
            state?.marquee?.map(item => item.marquee_id === payload.marquee_id ?
              { ...item, ...payload } : item
            )
        }
      }
    }
    case MARQUEE_DETAILS_NOTIFICATION:
      return {
        ...state,
        marquee_dettails_notification: (payload.message) ? payload : initialState.marquee_dettails_notification
      }

    case DELETE_MARQUEE_DETAILS:
      return {
        ...state,
        marquee: state.marquee.filter(item => item.marquee_id !== payload.marqueeId)
      }
    default:
        return state;
    }
}

export default reducer;