const { GET_INVOICE_LIST, INVOICE_LIST_NOTIFICATION } = require('redux/reseller-constants');

  const initialState = {
    invoiceList: [],
    invoiceListNotification: {
      message: '',
      isSuccess: 1,
    },
  }; 

  const reducer = (state = initialState, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case GET_INVOICE_LIST: {
        return {
          ...state,
          invoiceList: payload,
        };
      }
     
      case INVOICE_LIST_NOTIFICATION: {
        return {
          ...state,
          invoiceListNotification: payload.message
            ? payload
            : initialState.invoiceListNotification,
        };
      }
      default:
        return state;
    }
  };
  
  export default reducer;