const { GET_INTERNATIONAL_PRICE, UPDATE_INTERNATIONAL_PRICE, PRICE_UPLOAD_NOTIFICATION } = require("redux/admin-constants");

const initialState = {
  priceUpload: [],
  price_upload_notification:{
    message: '',
    isSuccess: 0
}
}

const reducer = (state = initialState, action) => {
  const {
    type,
    payload
  } = action;

  switch (type) {
    case GET_INTERNATIONAL_PRICE: {

      return {
        ...state,
        priceUpload: payload,
      }
    }

    case UPDATE_INTERNATIONAL_PRICE: {
        return {
          ...state,
          priceUpload: state.priceUpload.map(item => item.priceListId === payload.priceListId ? 
            { ...item, ...payload} : item 
          )
        }
      }

      case PRICE_UPLOAD_NOTIFICATION:
      return { ...state, 
        price_upload_notification: (payload.message) ? payload : initialState.price_upload_notification
    }

    default:
      return state;
  }
}
export default reducer;