const { GET_SALES_REPORT_CARDWISE } = require('redux/admin-constants');

  const initialState = {
    salesReportCardwise: [],
  }; 

  const reducer = (state = initialState, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case GET_SALES_REPORT_CARDWISE: {
        return {
          ...state,
          salesReportCardwise: payload,
        };
      }
      default:
        return state;
    }
  };
  
  export default reducer;