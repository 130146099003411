const { GET_GUEST_ADMIN_LIST, GUEST_ADMIN_DETAILS_NOTIFICATION, UPDATE_GUEST_ADMIN_DETAILS, INSERT_GUEST_ADMIN_DETAILS, DELETE_GUEST_ADMIN_DETAILS } = require("redux/admin-constants");

const initialState = {
  guest_admin: [],
  guest_admin_dettails_notification: {
    message: '',
    isSuccess: 1
  }
}

const reducer = (state = initialState, action) => {
  const {
    type,
    payload
  } = action;

  switch (type) {
    case GET_GUEST_ADMIN_LIST: {
      return {
        ...state,
        guest_admin: payload,
      }
    }

    case INSERT_GUEST_ADMIN_DETAILS: {
      return { ...state, guest_admin: [payload, ...state.guest_admin] }
    }

    case UPDATE_GUEST_ADMIN_DETAILS: {
      return {
        ...state,
        guest_admin:
          state.guest_admin.map(item => item.user_id === payload.user_id ?
            { ...item, ...payload } : item
          )
      }
    }
    case GUEST_ADMIN_DETAILS_NOTIFICATION:
      return {
        ...state,
        guest_admin_dettails_notification: (payload.message) ? payload : initialState.guest_admin_dettails_notification
      }
    case DELETE_GUEST_ADMIN_DETAILS:
      return {
        ...state,
        guest_admin: state.guest_admin.filter(item => item.user_id !== payload.userId)
      }

    default:
      return state;
  }
}

export default reducer;