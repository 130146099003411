const { GET_CASH_FLOW_REPORT } = require('redux/reseller-constants');

const initialState = {
  cashFlowReport: [],
};

const reducer = (state = initialState, action) => {

  const { type, payload } = action;

  switch (type) {

    case GET_CASH_FLOW_REPORT: {
      return {
        ...state,
        cashFlowReport: payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;