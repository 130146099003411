const {
  GET_INCHARGE_LIST1,
  INSERT_INCHARGE_DETAILS,
  UPDATE_INCHARGE_DETAILS,
  DELETE_INCHARGE_DETAILS,
  INCHARGE_LIST_NOTIFICATION,
} = require('redux/admin-constants');

const initialState = {
  incharge_list: [],
  incharge_list_notification: {
    message: '',
    isSuccess: 1,
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_INCHARGE_LIST1: {
      return {
        ...state,
        incharge_list: payload,
      };
    }
    case INSERT_INCHARGE_DETAILS: {
      return {
        ...state,
        incharge_list: [payload, ...state.incharge_list],
      };
    }
    case UPDATE_INCHARGE_DETAILS: {
      return {
        ...state,
        incharge_list: state.incharge_list.map((item) =>
          item.incharge_id === payload.incharge_id
            ? { ...item, ...payload }
            : item
        ),
      };
    }
    case DELETE_INCHARGE_DETAILS: {
      return {
        ...state,
        incharge_list: state.incharge_list.filter(
          (item) => item.incharge_id !== payload.inchargeId
        ),
      };
    }
    case INCHARGE_LIST_NOTIFICATION: {
      return {
        ...state,
        incharge_list_notification: payload.message
          ? payload
          : initialState.incharge_list_notification,
      };
    }
    default:
      return state;
  }
};

export default reducer;
